import React, { Suspense, lazy, Component }  from "react"
import { Route, Switch } from "react-router-dom"
const Home = lazy(() => import("./components/Home"))
const CreateFeedback = lazy(() => import("./components/feedback/CreateFeedback"))
const ThankYou = lazy(() => import('./components/feedback/ThankYou'))
const HomeTrack = lazy(() => import('./components/PageViewsTracking/HomeTrack'))
const FeedbackTrack = lazy(() => import('./components/PageViewsTracking/FeedbackTrack'))



function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
    <div>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/feedback" component={CreateFeedback} />
        <Route path="/thankyou" component={ThankYou} />
        <Route path="/homepage/:key" component={HomeTrack} />
        <Route path="/feedbackform/:key" component={FeedbackTrack} />
      </Switch>
    </div>
    </Suspense>
  )
}

export default App
